/** @format */

import React from 'react';
import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import { Links } from './links';

import type { FooterLink } from './linkType';
import type { ReactElement, FunctionComponent } from 'react';

interface Props {
    title: string;
    links: FooterLink[];
    useAccordion?: boolean;
    className?: string;
}
export const AccordionLinks: FunctionComponent<Props> = ({ title, links, useAccordion }: Props): ReactElement => {
    if (useAccordion) {
        return (
            <div className={'links-column'}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div className={'column-header'}>{title}</div>
                            <AccordionItemState>
                                {(state) => {
                                    return state.expanded ? (
                                        <i className={'d-icon d-arrowhead-up'} />
                                    ) : (
                                        <i className={'d-icon d-arrowhead-down'} />
                                    );
                                }}
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={'accordion__panel-items'}>
                            <Links links={links} />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </div>
        );
    } else {
        return (
            <div className={'flex flex-column links-column'}>
                <div className={'column-header hover-bg-color'}>{title}</div>
                <Links links={links} />
            </div>
        );
    }
};
