/** @format */

import React, { useContext } from 'react';

import {
    AMP_PAGE,
    BEST_PRACTICES_PAGE,
    BIMI_PAGE,
    COMPLAINT_FEEDBACK_LOOP_PAGE,
    CONTACT_PAGE,
    DELIVERABILITY_FAQS_PAGE,
    DEVELOPER_ACCESS_PAGE,
    DOCUMENTATION_PAGE,
    EMAIL_DELIVERABILITY_PERFORMANCE_FEEDS_PAGE,
    FEATURE_MANAGEMENT_PAGES,
    MAIL_PROXY_SERVERS_PAGE,
    OUTBOUND_MAIL_SERVERS_PAGE,
    PROMOTIONS_AND_SCHEMA_PAGE,
    SMTP_ERROR_CODES_PAGE,
    SUBHUB_PAGE
} from '../../../../links';
import { PathnameContext } from '../pathnameContext';
import { UserContext } from '../userContext';

import { createNavbarLink, createNavbarMenu } from './linkUtil';

import type { NavbarLink, NavbarMenu } from './linkUtil';
import type { ReactElement, FunctionComponent } from 'react';

export const navbarLinks: (NavbarLink | NavbarMenu)[] = [
    {
        title: 'Dashboard',
        href: FEATURE_MANAGEMENT_PAGES.DASHBOARD
    },
    {
        id: 'navbar-sender-features',
        title: 'Sender Features',
        children: [
            {
                title: 'Complaint Feedback Loop',
                href: COMPLAINT_FEEDBACK_LOOP_PAGE
            },
            {
                title: 'AMP',
                href: AMP_PAGE
            },
            {
                title: 'Promotions & Schema',
                href: PROMOTIONS_AND_SCHEMA_PAGE
            },
            {
                title: 'BIMI',
                href: BIMI_PAGE
            },
            {
                title: 'Subscription Hub',
                href: SUBHUB_PAGE
            },
            {
                title: 'Performance Feeds',
                href: EMAIL_DELIVERABILITY_PERFORMANCE_FEEDS_PAGE
            }
        ]
    },
    {
        id: 'navbar-deliverability',
        title: 'Deliverability',
        children: [
            {
                title: 'Sender Requirements & Recommendations',
                href: BEST_PRACTICES_PAGE
            },
            {
                title: 'FAQs',
                href: DELIVERABILITY_FAQS_PAGE
            },
            {
                title: 'Error Codes',
                href: SMTP_ERROR_CODES_PAGE
            },
            {
                title: 'Outbound Mail Servers',
                href: OUTBOUND_MAIL_SERVERS_PAGE
            },
            {
                title: 'Mail Proxy Servers',
                href: MAIL_PROXY_SERVERS_PAGE
            }
        ]
    },
    {
        title: 'Developers',
        children: [
            {
                title: 'API Access',
                href: DEVELOPER_ACCESS_PAGE
            },
            {
                title: 'Documentation',
                href: DOCUMENTATION_PAGE
            }
        ]
    },
    {
        title: 'Contact',
        href: CONTACT_PAGE
    }
];

interface Props {
    setNavbarOpen: (isOpen: boolean) => void;
}

export const NavbarLinks: FunctionComponent<Props> = ({ setNavbarOpen }: Props): ReactElement => {
    const pathname = useContext(PathnameContext);
    const { loggedInUser } = useContext(UserContext);

    const navbarLinksToDisplay = [...navbarLinks];

    if (!loggedInUser) {
        navbarLinksToDisplay.shift();
    }

    return (
        <>
            {navbarLinksToDisplay.map((navbarItem, index) => {
                let navbarItemFragment: ReactElement;

                // @ts-expect-error check href to determine if we are working with a NavbarLink or NavbarMenu
                if (navbarItem.href) {
                    navbarItemFragment = createNavbarLink(navbarItem as NavbarLink, pathname, setNavbarOpen);
                } else {
                    navbarItemFragment = createNavbarMenu(navbarItem as NavbarMenu, pathname, setNavbarOpen);
                }

                return <React.Fragment key={index}>{navbarItemFragment}</React.Fragment>;
            })}
        </>
    );
};
