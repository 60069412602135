import * as log from 'loglevel';

declare global {
    interface Window {
        log: typeof log;
    }
}

// so we can set the log level with log.setLevel() at runtime
if (typeof window !== 'undefined') {
    if (!window.log) {
        window.log = log;
        if (process.env.GATSBY_LOCAL_DEV) {
            log.setLevel('debug');
        } else {
            log.setLevel('error');
        }
    }
}

export default log;
