/** @format */

import { DnMenuAlignment, DnMenu, DnNavbar } from '@denali-design/react';
import classNames from 'classnames';
import { Link, withPrefix } from 'gatsby';
import React from 'react';

import type { ReactElement } from 'react';

export interface NavbarLink {
    title: string;
    href: string;
}

export interface NavbarMenu {
    title: string;
    children: NavbarLink[];
    id?: string;
}

function isLinkActive(pathname: string, linkDestination: string): boolean {
    const href = linkDestination.endsWith('/') ? linkDestination : linkDestination + '/';

    return pathname === withPrefix(href);
}

function getNavbarLink(navbarLink: NavbarLink, pathname: string, setNavbarOpen: (isOpen: boolean) => void) {
    const isActive = isLinkActive(pathname, navbarLink.href);

    return {
        isActive: isActive,
        component: (
            <Link
                title={navbarLink.title}
                to={navbarLink.href}
                className={classNames({ 'is-active': isActive })}
                role="menuitem"
                tabIndex={0}
                onClick={() => {
                    setNavbarOpen(false);
                }}
            >
                {navbarLink.title}
            </Link>
        )
    };
}

export const createNavbarLink = (
    navbarLink: NavbarLink,
    pathname: string,
    setNavbarOpen: (isOpen: boolean) => void
): ReactElement => {
    const linkComponent = getNavbarLink(navbarLink, pathname, setNavbarOpen);

    return <DnNavbar.Item isActive={linkComponent.isActive}>{linkComponent.component}</DnNavbar.Item>;
};

export const createNavbarMenu = (
    menuItem: NavbarMenu,
    pathname: string,
    setNavbarOpen: (isOpen: boolean) => void
): ReactElement => {
    const isActive = menuItem.children.some((navbarItem) => {
        return isLinkActive(pathname, navbarItem.href);
    });

    return (
        <DnMenu
            // @ts-expect-error id is always allowed for react components
            id={menuItem.id}
            menuNav={'true'}
            alignment={DnMenuAlignment.left}
            role="menu"
        >
            {/* using the raw link since I can't set a tabIndex on the DnMenu.Trigger component */}
            <a
                className={classNames('menu-trigger', 'nav-item', { 'is-active': isActive })}
                tabIndex={0}
            >
                {menuItem.title}
            </a>
            <DnMenu.Content>
                {menuItem.children.map((navbarLink, index) => {
                    const linkComponent = getNavbarLink(navbarLink, pathname, setNavbarOpen);

                    return <React.Fragment key={index}>{linkComponent.component}</React.Fragment>;
                })}
            </DnMenu.Content>
        </DnMenu>
    );
};
