/** @format */

import React from 'react';

import { OutboundLink } from '../anchor/outboundLink';

import { getButtonClasses } from './buttonUtils';

import type { AnchorButtonProps } from './buttonUtils';
import type { Props as OutboundLinkProps } from '../anchor/outboundLink';
import type { FunctionComponent, ReactElement } from 'react';

export type Props = OutboundLinkProps & AnchorButtonProps;

export const OutboundLinkButton: FunctionComponent<Props> = ({
    className,
    isActive,
    style,
    size,
    isInverse,
    isDisabled,
    isFull,
    ...props
}: Props): ReactElement => {
    return (
        <OutboundLink
            className={getButtonClasses(className, style, size, isActive, isInverse, isDisabled, isFull)}
            {...props}
        />
    );
};
