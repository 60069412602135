/** @format */

import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import { Footer } from './footer/footer';
import { Navbar } from './navbar/navbar';
import { PathnameContext } from './navbar/pathnameContext';
import { UserContextProvider } from './navbar/userContext';

import type { ReactElement } from 'react';

// region fonts
import '@denali-design/icons/dist/font/denali-icons-font.css';
import '../../styles/fonts/Yahoo Sans Web Fonts 170914/fonts.css';
//endregion
import '../../styles/main.scss';

const senderHub = 'sender-hub';

export const layoutId = senderHub;
export const layoutClass = senderHub;

const pagesWithNoLayout = ['/feature-management/sender-profile/'];

export const wrapWithLayout = (element: ReactElement, props: unknown): ReactElement => {
    const { location } = props as { location: Location };

    const isPageWithNoLayout = pagesWithNoLayout.some((page) => {
        return location.pathname.includes(page);
    });

    useEffect(() => {
        ReactModal.setAppElement(`#${senderHub}`);
    }, []);

    return (
        <PathnameContext.Provider value={location.pathname}>
            <UserContextProvider>
                <div
                    id={senderHub}
                    className={senderHub}
                >
                    {!isPageWithNoLayout && <Navbar location={location} />}

                    <main>{element}</main>

                    {!isPageWithNoLayout && <Footer />}
                </div>
            </UserContextProvider>
        </PathnameContext.Provider>
    );
};
