/** @format */

import { DnButtonStyles } from '@denali-design/react';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { useContext, useState } from 'react';

// @ts-expect-error ReactComponent isn't exposed by gatsby-plugin-svgr
import { ReactComponent as ChevronDown } from '../../../../images/svg/chevron_down.svg';
import { FEATURE_MANAGEMENT_PAGES, SENDER_HUB_API, YAHOO_SENDER_HUB_FEEDBACK_EMAIL } from '../../../../links';
import { OutboundLink } from '../../../links/anchor/outboundLink';
import { OutboundLinkButton } from '../../../links/buttons/outboundLinkButton';
import { UserContext } from '../userContext';

import type { FunctionComponent, ReactElement } from 'react';

interface Props {
    mobile?: boolean;
}

interface MenuItem {
    label: string;
    url: string;
    external?: boolean;
}

const menuItems = [
    {
        label: 'Domains',
        url: FEATURE_MANAGEMENT_PAGES.DOMAIN_MANAGEMENT
    },
    {
        label: 'Manage Services',
        url: FEATURE_MANAGEMENT_PAGES.SERVICE_MANAGEMENT
    },
    {
        label: 'Settings',
        url: FEATURE_MANAGEMENT_PAGES.SETTINGS
    },
    {
        label: 'Send Feedback',
        url: YAHOO_SENDER_HUB_FEEDBACK_EMAIL,
        external: true
    },
    {
        label: 'Sign out',
        url: SENDER_HUB_API.SIGN_OUT
    }
];

const Login: FunctionComponent<Props> = ({ mobile = false }): ReactElement => {
    const { loggedInUser, loadingLogin } = useContext(UserContext);
    const [chevronFlipped, setChevronFlipped] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    function renderMobileLogin() {
        if (loggedInUser) {
            return (
                <div
                    className={classNames('login-mobile float-right hide-small-desktop-up menu menu--nav', {
                        'is-active': isMobileOpen
                    })}
                >
                    <div
                        className="nav-item"
                        onClick={() => {
                            setIsMobileOpen(!isMobileOpen);
                        }}
                    >
                        <img
                            className={'profile-image'}
                            src={loggedInUser.profile_images.image64}
                            alt={`${loggedInUser.given_name} ${loggedInUser.family_name}`}
                        />
                    </div>
                    <div className="menu-content">
                        <div className="nav-item profile-section">
                            <div className="profile-name">
                                {loggedInUser.given_name} {loggedInUser.family_name}
                            </div>
                            <div className="profile-email">{loggedInUser.user.contactEmail}</div>
                        </div>
                        <div className="menu-items">
                            {menuItems.map((item: MenuItem, index: number) => {
                                if (item.external) {
                                    return (
                                        <OutboundLink
                                            key={index}
                                            href={item.url}
                                            title={item.label}
                                            className="nav-item"
                                        >
                                            {item.label}
                                        </OutboundLink>
                                    );
                                }

                                return (
                                    <Link
                                        key={index}
                                        to={item.url}
                                        title={item.label}
                                        className="nav-item"
                                        onClick={() => {
                                            setIsMobileOpen(false);
                                        }}
                                    >
                                        {item.label}
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        } else if (!loadingLogin && !loggedInUser) {
            return (
                <div className="login-mobile sign-in float-right hide-small-desktop-up">
                    <OutboundLinkButton
                        className={'sign-button sign-in'}
                        href={SENDER_HUB_API.SIGN_IN}
                        title={'Sign In'}
                        style={DnButtonStyles.outline}
                    >
                        Sign In
                    </OutboundLinkButton>
                </div>
            );
        }

        return <div className={'login loading'}></div>;
    }

    function renderDesktopLogin() {
        if (loggedInUser) {
            return (
                <div
                    className="login menu hide-tablet-down"
                    style={{ display: 'inline-block' }}
                    onFocus={() => {
                        setChevronFlipped(true);
                    }}
                    onBlur={() => {
                        setChevronFlipped(false);
                    }}
                    onMouseEnter={() => {
                        setChevronFlipped(true);
                    }}
                    onMouseLeave={() => {
                        setChevronFlipped(false);
                    }}
                >
                    <div
                        className="menu-trigger"
                        tabIndex={0}
                    >
                        <img
                            className={'profile-image'}
                            src={loggedInUser.profile_images.image64}
                            alt={`${loggedInUser.given_name} ${loggedInUser.family_name}`}
                        />
                        <div className={'username hide-small-desktop-down'}>
                            {loggedInUser.given_name} {loggedInUser.family_name}
                        </div>

                        <div className={'chevron'}>
                            <ChevronDown className={chevronFlipped ? 'chevron-up' : 'chevron-down'} />
                        </div>
                    </div>
                    <div className="menu-content">
                        {menuItems.map((item: MenuItem, index: number) => {
                            return (
                                <a
                                    key={index}
                                    tabIndex={0}
                                    onClick={() => {
                                        if (item.external) {
                                            window.location.href = item.url;
                                        } else {
                                            void navigate(item.url);
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            if (item.external) {
                                                window.location.href = item.url;
                                            } else {
                                                void navigate(item.url);
                                            }
                                        }
                                    }}
                                >
                                    {item.label}
                                </a>
                            );
                        })}
                    </div>
                </div>
            );
        } else if (!loadingLogin && !loggedInUser) {
            return (
                <div className={'login sign-in hide-tablet-down'}>
                    <OutboundLinkButton
                        className={'sign-button sign-up hide-small-desktop-down'}
                        href={SENDER_HUB_API.SIGN_UP}
                        title={'Sign Up'}
                    >
                        Sign Up
                    </OutboundLinkButton>
                    <OutboundLinkButton
                        className={'sign-button sign-in'}
                        href={SENDER_HUB_API.SIGN_IN}
                        title={'Sign In'}
                        style={DnButtonStyles.outline}
                    >
                        Sign In
                    </OutboundLinkButton>
                </div>
            );
        }

        return <div className={'login loading'}></div>;
    }

    if (mobile) {
        return renderMobileLogin();
    }

    return renderDesktopLogin();
};

export default Login;
