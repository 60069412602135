/** @format */

import React from 'react';

// region SVGs
// @ts-expect-error ReactComponent isn't exposed by gatsby-plugin-svgr
import { ReactComponent as YahooLogo } from './svg/yahoo-logo-purple.svg';
// endregion

import type { ReactElement } from 'react';

export const Copyright = (): ReactElement => {
    return (
        <>
            <div className={'flex align-items-flex-end flex-row'}>
                <div className={'flex flex-column align-items-end'}>
                    <YahooLogo className={'footer-brand'} />
                    <span className={'copyright'}>© {new Date().getFullYear()} Yahoo. All rights reserved.</span>
                </div>
            </div>
        </>
    );
};
