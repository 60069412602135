/** @format */

import React from 'react';

import { OutboundLink } from '../../../links/anchor/outboundLink';

import type { FooterLink } from './linkType';
import type { FunctionComponent, ReactElement } from 'react';

interface Props {
    links: FooterLink[];
}

export const Links: FunctionComponent<Props> = ({ links }: Props): ReactElement => {
    return (
        <>
            {links.map((item, index) => {
                const { image, multiUrl, withIcon, ...props } = item;

                if (multiUrl) {
                    return (
                        <div
                            key={index}
                            className={'multi-link'}
                        >
                            {multiUrl.map((multiUrlLink, index) => {
                                if (multiUrlLink.href) {
                                    return (
                                        <OutboundLink
                                            key={index}
                                            href={multiUrlLink.href}
                                        >
                                            {multiUrlLink.label}
                                        </OutboundLink>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={index}
                                            className={'multi-url'}
                                        >
                                            {multiUrlLink.label}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                }
                if (withIcon) {
                    return (
                        <span
                            key={index}
                            className={'with-icon'}
                        >
                            {/* eslint-disable-next-line react/prop-types */}
                            <OutboundLink {...props}>{props.title}</OutboundLink>
                            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                            {React.createElement(image!)}
                        </span>
                    );
                }

                return (
                    <OutboundLink
                        key={index}
                        {...props}
                    >
                        {/* eslint-disable-next-line react/prop-types */}
                        {image ? React.createElement(image) : props.title}
                    </OutboundLink>
                );
            })}
        </>
    );
};
