/** @format */

import { Link } from 'gatsby';
import React from 'react';

import { OutboundLink } from './anchor/outboundLink';

import type { ReactElement } from 'react';

function createLinkFragment(href: string, title: string, isExternal: boolean, text?: string): ReactElement {
    const displayText = text ? text : title;

    if (isExternal) {
        return (
            <OutboundLink
                href={href}
                title={title}
            >
                {displayText}
            </OutboundLink>
        );
    } else {
        return (
            <Link
                to={href}
                title={title}
            >
                {displayText}
            </Link>
        );
    }
}

export const getLink = (to: string, title: string, text?: string): ReactElement => {
    return createLinkFragment(to, title, false, text);
};

export const getOutboundLink = (href: string, title: string, text?: string): ReactElement => {
    return createLinkFragment(href, title, true, text);
};
