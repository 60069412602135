exports.components = {
  "component---src-pages-amp-index-tsx": () => import("./../../../src/pages/amp/index.tsx" /* webpackChunkName: "component---src-pages-amp-index-tsx" */),
  "component---src-pages-best-practices-index-tsx": () => import("./../../../src/pages/best-practices/index.tsx" /* webpackChunkName: "component---src-pages-best-practices-index-tsx" */),
  "component---src-pages-bimi-index-tsx": () => import("./../../../src/pages/bimi/index.tsx" /* webpackChunkName: "component---src-pages-bimi-index-tsx" */),
  "component---src-pages-complaint-feedback-loop-index-tsx": () => import("./../../../src/pages/complaint-feedback-loop/index.tsx" /* webpackChunkName: "component---src-pages-complaint-feedback-loop-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-developer-developer-access-index-tsx": () => import("./../../../src/pages/developer/developer-access/index.tsx" /* webpackChunkName: "component---src-pages-developer-developer-access-index-tsx" */),
  "component---src-pages-developer-developer-access-mail-form-index-tsx": () => import("./../../../src/pages/developer/developer-access-mail-form/index.tsx" /* webpackChunkName: "component---src-pages-developer-developer-access-mail-form-index-tsx" */),
  "component---src-pages-developer-documentation-index-tsx": () => import("./../../../src/pages/developer/documentation/index.tsx" /* webpackChunkName: "component---src-pages-developer-documentation-index-tsx" */),
  "component---src-pages-email-deliverability-performance-feeds-index-tsx": () => import("./../../../src/pages/email-deliverability-performance-feeds/index.tsx" /* webpackChunkName: "component---src-pages-email-deliverability-performance-feeds-index-tsx" */),
  "component---src-pages-event-index-tsx": () => import("./../../../src/pages/event/index.tsx" /* webpackChunkName: "component---src-pages-event-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-feature-management-access-control-tsx": () => import("./../../../src/pages/feature-management/accessControl.tsx" /* webpackChunkName: "component---src-pages-feature-management-access-control-tsx" */),
  "component---src-pages-feature-management-account-overview-index-tsx": () => import("./../../../src/pages/feature-management/account-overview/index.tsx" /* webpackChunkName: "component---src-pages-feature-management-account-overview-index-tsx" */),
  "component---src-pages-feature-management-bulk-add-domains-index-tsx": () => import("./../../../src/pages/feature-management/bulk-add-domains/index.tsx" /* webpackChunkName: "component---src-pages-feature-management-bulk-add-domains-index-tsx" */),
  "component---src-pages-feature-management-dashboard-index-tsx": () => import("./../../../src/pages/feature-management/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-feature-management-dashboard-index-tsx" */),
  "component---src-pages-feature-management-sender-profile-index-tsx": () => import("./../../../src/pages/feature-management/sender-profile/index.tsx" /* webpackChunkName: "component---src-pages-feature-management-sender-profile-index-tsx" */),
  "component---src-pages-feature-management-services-cfl-index-tsx": () => import("./../../../src/pages/feature-management/services/cfl/index.tsx" /* webpackChunkName: "component---src-pages-feature-management-services-cfl-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mail-proxy-servers-index-tsx": () => import("./../../../src/pages/mail-proxy-servers/index.tsx" /* webpackChunkName: "component---src-pages-mail-proxy-servers-index-tsx" */),
  "component---src-pages-outbound-mail-servers-index-tsx": () => import("./../../../src/pages/outbound-mail-servers/index.tsx" /* webpackChunkName: "component---src-pages-outbound-mail-servers-index-tsx" */),
  "component---src-pages-promotions-and-schema-index-tsx": () => import("./../../../src/pages/promotions-and-schema/index.tsx" /* webpackChunkName: "component---src-pages-promotions-and-schema-index-tsx" */),
  "component---src-pages-smtp-error-codes-index-tsx": () => import("./../../../src/pages/smtp-error-codes/index.tsx" /* webpackChunkName: "component---src-pages-smtp-error-codes-index-tsx" */),
  "component---src-pages-subhub-index-tsx": () => import("./../../../src/pages/subhub/index.tsx" /* webpackChunkName: "component---src-pages-subhub-index-tsx" */)
}

