/** @format */

import React from 'react';
import { Accordion } from 'react-accessible-accordion';

import { Copyright } from './copyright';
import { AccordionLinks } from './links/accordionLinks';
import { getPrivacyJurisdictionLinks } from './privacyJurisdiction/privacyJurisdictionLinks';

import type { FooterLink } from './links/linkType';
import type { FunctionComponent, ReactElement } from 'react';

export const Footer: FunctionComponent = (): ReactElement => {
    const supportTitle = 'Support';
    const brandsTitle = 'Brands';
    const privacyAndSupportLinks: FooterLink[] = getPrivacyJurisdictionLinks().concat([
        {
            title: 'About Us',
            href: 'https://yahooinc.com'
        }
    ]);
    const brandsLinks: FooterLink[] = [
        {
            title: 'AOL',
            href: 'https://www.aol.com'
        },
        {
            title: 'Yahoo',
            href: 'https://www.yahoo.com'
        }
    ];

    return (
        <footer>
            <div className={'container-full'}>
                <div className={'row md-hide lg-hide xl-hide'}>
                    <Accordion
                        allowMultipleExpanded
                        allowZeroExpanded
                        className={'accordion'}
                    >
                        <AccordionLinks
                            title={supportTitle}
                            links={privacyAndSupportLinks}
                            useAccordion
                        />
                        <AccordionLinks
                            title={brandsTitle}
                            links={brandsLinks}
                            useAccordion
                        />
                    </Accordion>
                    <Copyright />
                </div>
                <div className={'row xs-hide sm-hide flex justify-content-center'}>
                    <AccordionLinks
                        title={supportTitle}
                        links={privacyAndSupportLinks}
                    />
                    <AccordionLinks
                        title={brandsTitle}
                        links={brandsLinks}
                    />
                    <Copyright />
                </div>
            </div>
        </footer>
    );
};
