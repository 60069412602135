/** @format */

import { OutboundLink as GtaggedOutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

import type { OutboundLinkProps } from 'gatsby-plugin-google-gtag';
import type { FunctionComponent, ReactElement, HTMLProps } from 'react';

export type Props = HTMLProps<HTMLAnchorElement>;

export const OutboundLink: FunctionComponent<Props> = ({ ...props }: Props): ReactElement => {
    return (
        <GtaggedOutboundLink
            target={'_blank'}
            rel={'noreferrer'}
            {...(props as OutboundLinkProps)}
        />
    );
};
