/** @format */

import { PrivacyJurisdiction } from '@vzmi/privacy-jurisdiction-sdk';
import { useContext } from 'react';

import { PrivacyJurisdictionContext } from './privacyJurisdictionContext';
// region SVGs
// @ts-expect-error ReactComponent isn't exposed by gatsby-plugin-svgr
import { ReactComponent as PrivacyControl } from './svg/privacy-control.svg';
// endregion

import type { FooterLink, MultiUrlFooterLink } from '../links/linkType';
import type { IncomingHttpHeaders } from 'http';
import type { FunctionComponent } from 'react';

export function getPrivacyJurisdictionLinks(): FooterLink[] {
    const privacyJurisdictionHeaders = useContext(PrivacyJurisdictionContext) as IncomingHttpHeaders | null;

    if (privacyJurisdictionHeaders === null) {
        return [];
    }

    const privacy = new PrivacyJurisdiction();
    const data = privacy.getPrivacyLinksV2(
        privacy.NAMESPACE_YAHOO,
        undefined,
        'en-US',
        privacyJurisdictionHeaders,
        false
    );

    return data.footerSequence.map((key) => {
        const link = data.links[key] as {
            url: string;
            label: string;
            showPrivacyControlIcon?: boolean;
            multiurl: boolean;
            urls: Record<string, string>;
        };

        if (link.multiurl) {
            const links: MultiUrlFooterLink[] = [];
            // Regex to split the multiurl label which looks like this:
            // ${terms_link}Terms${end_link} and ${privacy_link}Privacy Policy${end_link}
            // 1. The regex will save the ${*_link} into a named group called linkKey so that we can use it to reference the URL
            // 2. end_link won't have a link, so we will be able to ignore that
            // 3. There is a named capture group for the actual label
            for (const match of link.label.matchAll(/{(?<linkKey>[a-z_]+)}(?<label>[A-Za-z ]+)/g)) {
                links.push({
                    // @ts-expect-error The regex should match and have something to work on
                    label: match.groups.label,
                    // @ts-expect-error The regex should match and have something to work on
                    href: link.urls[match.groups.linkKey]
                });
            }

            return {
                href: '',
                title: '',
                multiUrl: links
            };
        }

        return {
            href: link.url,
            title: link.label,
            image: link.showPrivacyControlIcon
                ? (PrivacyControl as FunctionComponent<SVGElement>)
                : (null as unknown as FunctionComponent<SVGElement>),
            withIcon: link.showPrivacyControlIcon
        };
    });
}
