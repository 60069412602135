/** @format */

import { DnButtonSizes, DnButtonStyles } from '@denali-design/react';
import classNames from 'classnames';

import type { DnButtonProps } from '@denali-design/react';

export type AnchorButtonProps = Omit<DnButtonProps, 'type' | 'isLoading' | 'children'>;

export const getButtonClasses = (
    className?: string,
    style = DnButtonStyles.solid,
    size = DnButtonSizes.default,
    isActive?: boolean,
    isInverse?: boolean,
    isDisabled?: boolean,
    isFull?: boolean
): string => {
    return classNames('button', className, {
        [`is-${style}`]: style,
        [`is-${size}`]: size,
        'is-active': isActive,
        'is-inverse': isInverse,
        'is-disabled': isDisabled,
        'is-full': isFull
    });
};
