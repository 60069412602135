/** @format */

import React from 'react';

import { PrivacyJurisdiction } from './src/components/layout/footer/privacyJurisdiction/privacyJurisdictionContext';
import { wrapWithLayout } from './src/components/layout/layout';

import type { GatsbyBrowser } from 'gatsby';
import type { ReactElement } from 'react';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }): ReactElement => {
    return wrapWithLayout(element, props);
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): ReactElement => {
    return <PrivacyJurisdiction>{element}</PrivacyJurisdiction>;
};
